.Nav-Container-Aboutus {
    padding-bottom: 60px;
}

.jss2 {
    width: 100%;
    height: auto;
    background: #4cff4c;
}

.jss3 {
    color: #FF6347;
    font-size: 45px;
    text-align: left;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    padding-top: 45px;
    padding-left: 150px;
    padding-bottom: 20px;
}

.orgdiv {
    background-color: orange;
    -webkit-clip-path: polygon(50% 0, 100% 30%, 100% 100%, 0 100%, 0 30%);
    clip-path: polygon(50% 0, 100% 30%, 100% 100%, 0 100%, 0 30%);
    height: 200px;
    margin-right: 200px;
    margin-top: 90px;
    position: absolute;
    right: 0;
    top: 0;
    width: 130px;
    z-index: 9;
}

.jss4 {
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    line-height: 1.8;
    padding-top: 35px;
    padding-left: 220px;
    padding-right: 260px;
}

.jss5 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: normal;
    padding-bottom: 35px;
    width: 90%;
}

.jss6 {
    font-weight: normal;
    padding-bottom: 10px;
}

.jss7 {
    ont-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    padding-top: 25px;
}

.jss8 {
    padding-bottom: 35px;
}

.jss9 {
    float: left;
    width: 40%;
}

.jss10 {
    width: 500px;
    height: 420px;
}

.jss11 {
    width: 340px;
    height: 250px;
    padding-left: 30px;
}



@media(max-width:1100px) {
    .jss3 {
        color: #FF6347;
        font-size: 30px;
        text-align: left;
        font-family: 'Courier New', Courier, monospace;
        font-weight: 600;
        padding-top: 16px;
        padding-left: 30px;
        line-height: normal;
        width: 80%;
    }

    .orgdiv {
        background-color: orange;
        height: 100px;
        width: 55px;
        position: absolute;
        z-index: 9;
        top: 0px;
        right: 0px;
        margin-top: 90px;
        margin-right: 19px;
        position: absolute;
        clip-path: polygon(50% 0%, 100% 30%, 100% 100%, 0 100%, 0% 30%);
    }

    .jss4 {
        font-size: large;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        line-height: 1.8;
        padding-top: 35px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .jss11 {
        width: 290px;
        height: 250px;
        padding: 10px;
        padding-left: 0;
    }

    .jss10 {
        width: 100%;
        height: auto;
    }
}

@media(max-width:768px) and (min-width:1220px) {
    .orgdiv {
        margin-top: 6%;
    }
}