.nfi{
  width: 100px;
  height: 60px;
}
.nav-tab{
  color: white;
  font-size: larger;
  text-decoration: none;
}
.nav-tab:hover{
  color: red;
  text-decoration: none;
}

.navbar-collapse{
  flex-direction: row-reverse;
}

@media(max-width:1100px) {
    .nfi {
    width: 80px;
    height: 45px;
}
}