.App-header{
    background-image: url("../../../public/images/homeImg.png");
    background-size: cover;
}
.home-title{
    padding-top: 23%;
    padding-right: 40%;
}
.home-click{
    padding-right: 40%;
}
.click{
    color: black
}
.click:hover{
    color: white;
}
